<template>
  <scan-step @scan="processQrCode"/>
</template>

<script setup>
import ScanStep from "@/components/CloseableModalStepperScanStep";
import {normalizeQrCode} from "@/qr";
import {modalController} from "@ionic/vue";
import router from "@/router";

const processQrCode = async (code) => {
  const qrCode = normalizeQrCode(code);
  if (!qrCode) {
    console.log('illegal qr code');
    //FIXME show toast
    return;
  }

  await modalController.dismiss();
  await router.push({name: 'padlock', params: {id: qrCode.id}});
}
</script>
