import {toastController} from '@ionic/vue';

const showToast = async function (message, color) {
    const toast = await toastController.create({
        message: message,
        cssClass: ['rubik-toast'],
        color: color,
        position: "middle",
        duration: 2000
    });

    await toast.present();
}

export async function showSuccess(message) {
    await showToast(message, 'success');
}

export async function showError(message) {
    await showToast(message, 'danger');
}