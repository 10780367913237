export function normalizeQrCode(code) {
    if (!code) {
        return {id: null, claimable: false};
    }

    const qrCode = code.toLowerCase().trim();
    if (qrCode.length === 0) {
        return {id: '', claimable: false};
    }

    if (qrCode.startsWith("!")) {
        return {
            id: qrCode.substring(1),
            claimable: true
        };
    }

    return {
        id: qrCode,
        claimable: false
    };
}