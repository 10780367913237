<script setup>
import PadlockScanner from "@/components/PadlockScanner";
import {IonButton} from "@ionic/vue";
import CloseableModalPanel from "@/components/CloseableModalPanel";
import i18n from "@/i18n";

const emit = defineEmits(['scan']);
const {t} = i18n.global;

const props = defineProps({
  suppressManualEntry: {
    type: Boolean,
    default: false
  }
});
</script>

<template>
  <closeable-modal-panel :title="t(`scan.title`)">

    <template v-slot:content>
      <padlock-scanner @scan="(id) => emit('scan', id)" :suppress-manual-entry="props.suppressManualEntry"/>
    </template>

    <template v-slot:buttons>
      <ion-button class="ion-no-margin" disabled="true" expand="full" size="large">
        {{ t('scan.action') }}
      </ion-button>
    </template>

  </closeable-modal-panel>
</template>

