import {createRouter, createWebHistory} from '@ionic/vue-router';
import store from '@/store';

const routes = [
    {
        path: '/',
        redirect: '/tabs/home'
    }, {
        path: '/settings',
        component: () => import('@/views/SettingsView.vue')
    }, {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginView.vue')
    }, {
        path: '/padlocks',
        component: () => import('@/views/PadlockListView.vue')
    }, {
        path: '/padlocks/:id',
        name: 'padlock',
        component: () => import('@/views/PadlockDetailView.vue'),
    }, {
        path: '/about',
        component: () => import('@/views/AboutView.vue')
    }, {
        path: '/tabs/',
        component: () => import('@/views/TabsView.vue'),
        children: [
            {
                path: '',
                redirect: '/tabs/home'
            },
            {
                path: 'home',
                component: () => import('@/views/TabsViewHomeTab.vue')
            },
            {
                path: 'search',
                component: () => import('@/views/TabsViewSearchTab.vue')
            },
            {
                path: 'collect',
                component: () => import('@/views/TabsViewCollectTab.vue')
            },
            {
                path: 'lock',
                component: () => import('@/views/TabsViewLockTab.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.query.app) {
        store.commit('app', to.query.app);
    }

    if (to.name !== 'login' && !store.getters.authenticated) {
        next({
            name: 'login'
        });
    }

    next();
})

export default router
