<template>
  <div id="scanner">
    <qrcode-stream @decode="(decodedString) => emit('scan', normalizeQrCode(decodedString))"/>
  </div>

  <p class="ion-padding-vertical">{{ t('scan.info') }}</p>

  <div class="ion-text-center" v-if="!props.suppressManualEntry">
    <ion-button color="tertiary" size="small" @click="showManualInput">{{ t('scan.manual-action') }} &nbsp;
      <ion-icon :icon="qrCodeOutline"/>
    </ion-button>
  </div>
</template>

<script setup>
import {alertController, IonButton, IonIcon} from "@ionic/vue";
import i18n from "@/i18n";
import {QrcodeStream} from "vue3-qrcode-reader";
import {qrCodeOutline} from "ionicons/icons";
import {normalizeQrCode} from "@/qr";

const emit = defineEmits(['scan']);
const {t} = i18n.global;

const props = defineProps({
  suppressManualEntry: {
    type: Boolean,
    default: false
  }
});

const showManualInput = async () => {
  const alert = await alertController.create({
    header: t('scan.manual.title'),
    inputs: [{
      placeholder: t('scan.manual.placeholder')
    }],
    buttons: [{
      text: t('scan.manual.cancel-action'),
      role: 'cancel',
      cssClass: 'secondary',
    }, {
      text: t('scan.manual.ok-action'),
      handler: (data) => {
        if (data && data[0]) {
          emit('scan', {id: data[0]});
        }
      },
    }],
  });
  await alert.present();
}
</script>


<style>
#scanner {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: lightgray;
}
</style>