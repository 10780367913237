import {createI18n} from 'vue-i18n/index'
import labelsDe from './labels-de.json'
import labelsEn from './labels-en.json'
import labelsIt from './labels-it.json'
import labelsFr from './labels-fr.json'

const i18n = createI18n({
    locale: window.navigator.language.substring(0, 2),
    fallbackLocale: 'en',
    messages: {
        en: labelsEn,
        de: labelsDe,
        it: labelsIt,
        fr: labelsFr
    },
    warnHtmlInMessage: 'off'
});

export default i18n;