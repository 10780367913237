import axios from 'axios';
import store from '@/store';
import {showError} from '@/toast';
import i18n from '@/i18n';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
})

const {t} = i18n.global

api.interceptors.response.use(async function (response) {
    if (response.config.method === 'put') {
        await store.dispatch('loadJobs');
    } else if (response.config.method === 'post' && response.config.url.toLowerCase().indexOf('padlock') !== -1) {
        await store.dispatch('loadPadlocks');
    }

    return response;
}, async function (error) {
    if (error.response.status === 401) {
        if (error.response.config.url.toLowerCase().indexOf('login') != -1) {
            await showError(t('login.wrongAuth-info'));
        } else {
            await showError(t('login.sessionExpired-info'));
        }
        store.commit('logout');
    } else if (error.response.status === 403) {
        await showError(t('login.notAuthorized-info'))
    } else if (error.response.data && error.response.data.violations && error.response.data.violations.includes("padlock_not_available")) {
        await showError(t('lock.padlockInUse-info'));
    } else {
        await showError(t('global.unknownError-info'));
    }

    return Promise.reject(error);
});

export default api;
