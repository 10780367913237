/* eslint-disable no-console */

import {register} from 'register-service-worker'
import {showError} from "@/toast";
import i18n from '@/i18n';

const {t} = i18n.global;

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound() {
            console.log('New content is downloading.')
        },
        async updated() {
            // await showSuccess(t('serviceWorker.update-info'));
            console.log("Update available, reload app.");
        },
        async offline() {
            await showError(t('serviceWorker.offline-info'));
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
